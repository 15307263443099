import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/lib/jenkins/workspace/jelly-bdc-prod-scheduled/www/bdc/src/templates/default-markdown/index.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Business.com strives to provide content that helps business owners make informed decisions to support and grow their companies. To do this, we research and recommend products and services that fit the needs of a variety of business types. We invest thousands of hours assessing a vast array of products and services each year. `}</p>
    <p>{`Of course, we are a business too. We need to generate revenue to support the content we create. To do this, we have financial relationships with some of the companies we cover. This means that in certain cases we take a commission when a reader buys something from one of our partners after reading an article on our site, or if we share information with a partner provided to us from readers about their needs for a product or service. `}</p>
    <p>{`Those relationships do not strictly dictate the advice and recommendations you see on our site. While we include our partners in the thousands of products and services we evaluate, the recommendations you see on our Best Picks and Review pages are produced independently by our editorial team based on their research and expertise. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      